import React, { useState, useRef, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Table, Icon, List, Loader, Message, Button, Header, Divider, Label, Popup } from 'semantic-ui-react';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';
import { unregisterERP } from 'http/erpBroker';
import { getFileMetadata, uploadFile, deleteFile } from 'http/file-storage';
import { setHoldingFiles, createHoldingTask, getHoldingTask } from 'http/internalTasks';
import { formatFileSize, getFileIcon, fileChecksum, getFileExtension } from 'util/files';
import ColoredText from 'design/atoms/ColoredText';
import { MANUEL_HOLDING_REVIEW_PRODUCT_ID } from 'design/pages/Payment/PaymentFlowManualHoldingReview';
import { getAddonProductByID, getAddonProductPurchases } from 'http/payment';
import { formatNumber } from 'util/format/Number';
import useRestResource from 'util/useRestResource';
import { useBreakpoints } from 'design/atoms/Media';
import ERPHeader from '../ERPHeader';

const FileUploadedMessage = () => (
    <span>
        <Icon name='check circle' />
        <p>En medarbejder kigger bilaget igennem snarest muligt.</p>
    </span>
);

const FileUploadTable = ({ files, onFileSelected, onFileRemoved, disabled, hasBought }) => {
    const hiddenUploadField = useRef();

    const uploadClicked = () => {
        if (!hiddenUploadField?.current) {
            return;
        }

        hiddenUploadField.current.click();
    };

    const onFileChosen = async e => {
        const file = e.target.files && e.target.files[0];
        if (!file) {
            return;
        }

        const checksum = await fileChecksum(file);
        if (files.find(f => f.checksum === checksum)) {
            toast.warn('Du har allerede vedhæftet valgte fil');
            return;
        }

        file.checksum = checksum;
        onFileSelected(file);
        
        // deselect file in hidden fileupload field
        e.target.value = '';
    };

    const removeFile = idx => {
        onFileRemoved(idx);
    };

    const renderTableHeader = () => {
        if (files.length === 0) {
            return null;
        }

        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Navn
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Størrelse
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
        );
    };

    const renderTableBody = () => {
        let content;

        if (files.length === 0) {
            content = <Table.Row>
                <Table.Cell>Du har ikke uploadet nogen bilag endnu</Table.Cell>
            </Table.Row>;
        } else {
            content = files.map((file, i) => (
                <Table.Row key={file.id || file.checksum}>
                    <Table.Cell>
                        <Icon name={getFileIcon(file, true)} />
                        {file.name}
                    </Table.Cell>
                    <Table.Cell>{formatFileSize(file)}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Icon
                            name='x'
                            color='black'
                            onClick={() => removeFile(i)}
                            link={!disabled}
                            disabled={disabled}
                        />
                    </Table.Cell>
                </Table.Row>
            ));
        }

        return <Table.Body>{content}</Table.Body>;
    };

    const uploadAttachmentButton = (
        <ColoredText
            link={hasBought}
            color={hasBought ? 'black' : 'grey'}
            onClick={hasBought ? uploadClicked : undefined}
            content='Upload nyt bilag'
            icon='upload'
            iconPosition='left'
            disabled={disabled}
        />
    );

    return (
        <Table>
            {renderTableHeader()}
            {renderTableBody()}
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell textAlign='right' colspan='3'>
                        <input
                            type='file'
                            style={{ display: 'none' }}
                            ref={hiddenUploadField}
                            onChange={onFileChosen}
                        />
                        {hasBought && uploadAttachmentButton}
                        {!hasBought && (
                            <Popup
                                basic
                                on='click'
                                content='Du har mulighed for at uploade bilag efter du har købt bogføringshjælpen'
                                trigger={uploadAttachmentButton}
                            />
                        )}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

const ManualReviewBooker = ({ taxYear, pagePrefix, onUnregister, runErpAction }) => {
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [task, setTask] = useState(null);
    const [working, setWorking] = useState();
    const breakpoints = useBreakpoints();

    const fetchManualReviewProductInfo = useCallback(async () => {
        if (!USE_CUSTOM_DISCOUNT_SYSTEM()) {
            return {
                hasAccessToManualReview: true,
                manualReviewProduct: {},
            };
        }

        const [manualReviewProduct, manualProductReviewPurchases] = await Promise.all([
            getAddonProductByID(MANUEL_HOLDING_REVIEW_PRODUCT_ID),
            getAddonProductPurchases(MANUEL_HOLDING_REVIEW_PRODUCT_ID),
        ]);
    
        const hasAccessToManualReview = manualProductReviewPurchases.some(purchase => {
            return purchase.data.taxYear === taxYear;
        });
    
        return { manualReviewProduct, hasAccessToManualReview };
    }, [taxYear]);

    const { loading: productInfoLoading, error, data } = useRestResource({ fetcher: fetchManualReviewProductInfo });

    const hasBought = data?.hasAccessToManualReview;

    const doFilesUpdate = (files) => {
        if (task) {
            task.done = false;
        }
        setFiles(files);
    };

    // load task on mount
    useEffect(() => {
        const loadTask = async () => {
            const htask = await getHoldingTask(taxYear);
            setTask(htask);
            setLoading(false);
        };
        loadTask();
    }, [taxYear]);

    // when task is loaded, fetch task files metadata
    useEffect(() => {
        if (!task) {
            return;
        }

        const getTaskFiles = async () => {
            setLoading(true);

            // fetch metadata for each task file
            const files = await Promise.all(task.files.map(fileID => {
                return getFileMetadata(fileID);
            }));
            
            setFiles([...files]);

            setLoading(false);
        };

        getTaskFiles();
    }, [task]);

    const onFileSelected = async file => {
        if (task) {
            try {
                // try upload file
                const fileID = await uploadFile(file);
                file.id = fileID;
            } catch (e) {
                switch (e.status) {
                    // file type not allowed
                    case 403:
                        toast.error(`Filtype ikke tilladt (.${getFileExtension(file)})`);
                        return;

                    // file is too big
                    case 413:
                        toast.error('Filen er for stor (maks 4 MB)');
                        return;

                    // unknown error
                    default:
                        toast.error('Der opstod en ukendt fejl');
                        return;
                }
            }

            // update file state serverside
            await setHoldingFiles(taxYear, [...files.map(f => f.id), file.id]);
            toast.success(<FileUploadedMessage />);
        }

        // update file table locally
        doFilesUpdate([...files, file]);
    };

    const onFileRemoved = async fidx => {
        const fileID = files[fidx].id;

        // remove file
        files.splice(fidx, 1);

        // if there is a task, update file state serverside
        if (task) {
            await Promise.all([
                // delete file serverside
                deleteFile(fileID),

                // update task files
                setHoldingFiles(taxYear, [...files.map(f => f.id)]),
            ]);
        }

        doFilesUpdate([...files]);
    };

    const orderReview = async () => {
        // ensure attachments
        if (files.length === 0) {
            toast.warn('Upload mindst 1 bilag');
            return;
        }

        setWorking(true);

        // TODO: confirn server side that the manual review product has been bought
        try {
            // create product link
            const link = `${pagePrefix}/index`;

            // upload files
            const fileIdentifiers = await Promise.all(files.map(file => {
                return uploadFile(file);
            }));
            
            // create internal task
            const ntask = await createHoldingTask(taxYear, fileIdentifiers, link);

            // set task => trigger scene change
            setTask(ntask);

            toast.success('Din bestilling blev oprettet');
        } catch {
            toast.error('Der opstod en fejl og gennemgangen kunne ikke bestilles');
        }

        setWorking(false);
    };

    const renderTaskStatus = () => {
        if (!task) {
            return null;
        }

        if (task?.done) {
            return (
                <Message
                    icon='check circle'
                    color='green'
                    header='Du er klar til at færddiggøre din årsrapport!'
                    content={
                        <span>
                            En medarbejder har gennemgået bilagene og gjort tallene klar til din årsrapport.
                        </span>
                    }
                />
            );
        }
        
        return (
            <Message
                icon='clock'
                color='blue'
                header='Vi klargør dine tal hurtigst muligt'
                content={
                    <span>
                        En medarbejder vil kigge dine bilag igennem snarest muligt.
                        Du vil modtage en mail, når tallene er gjort klar til årsrapporten.
                    </span>
                }
            />
        );
    };

    const renderBookingForm = () => {
        const { pathname, search, hash } = window.location;
        const redirectPath = pathname + search + hash;
        const purchaseLink = `/betaling/manuel-gennemgang/${taxYear}?redirect=${encodeURIComponent(redirectPath)}`
    
        const purchaseButton = !hasBought && (
            <Link to={purchaseLink}>
                <Button
                    primary
                    fluid={breakpoints.isMobile}
                    content={breakpoints.isMobile ? `Køb bogføringshjælp  for ${formatNumber(data.manualReviewProduct.price)} kr.` : 'Køb bogføringshjælp'}
                    label={breakpoints.isMobile ? undefined : <Label>{formatNumber(data.manualReviewProduct.price)} kr.</Label>}
                />
            </Link>
        );

        return (
            <div>
                {USE_CUSTOM_DISCOUNT_SYSTEM() && (
                    <ERPHeader
                        hideChangeErpButton={!!task}
                        onChangeErpClicked={async () => {
                            await runErpAction({ toggleManualReviewMode: true });
                            await unregisterERP();
                        }}
                    />
                )}
                {USE_CUSTOM_DISCOUNT_SYSTEM() && <Divider />}
                {!USE_CUSTOM_DISCOUNT_SYSTEM() && <Header>Bogføringshjælp</Header>}
                {renderTaskStatus()}
                <div>
                    {USE_CUSTOM_DISCOUNT_SYSTEM() && (
                        <>
                            <div style={{ display: 'flex', flexDirection: breakpoints.isMobile ? 'column' : 'row', marginBottom: '1em', gap: '0.5em' }}>
                                <div style={{ flex: 1 }}>
                                    <Header size='large'>Bogføringshjælp</Header>
                                </div>
                                <div>
                                    {purchaseButton}
                                </div>
                            </div>
                            <p>
                                Vi kan hjælpe dig med følgende:
                            </p>
                            <List bulleted>
                                <List.Item>Registreret kapital</List.Item>
                                <List.Item>Bankgebyrer</List.Item>
                                <List.Item>Kapitalandele i datterselskab</List.Item>
                                <List.Item>Udbyttehåndtering</List.Item>
                            </List>

                            <p>
                                Du skal selv bogføre, hvis du har flere end 20 posteringer eller har køb/salg af aktier.
                            </p>

                            <Divider />
                        </>
                    )}

                    <p>
                        Ønsker du vores hjælp til indtastning af tal, skal du uploade følgende dokumenter:
                    </p>
                    <List ordered>
                        <List.Item>Bankkontoudtog</List.Item>
                        <List.Item>Køb af datterselskab</List.Item>
                        <List.Item>Stiftelsesdokument (hvis det er første år)</List.Item>
                    </List>
                </div>
                <FileUploadTable
                    files={files}
                    onFileSelected={onFileSelected}
                    onFileRemoved={onFileRemoved}
                    disabled={working}
                    hasBought={hasBought}
                />
                {!task && (
                    <div>
                        {USE_CUSTOM_DISCOUNT_SYSTEM() && (
                            <Button content='Jeg vil bogføre manuelt' icon='arrow left' onClick={() => runErpAction({ toggleManualReviewMode: true })} />
                        )}

                        {!USE_CUSTOM_DISCOUNT_SYSTEM() && (
                            <Button content='Vælg bogføringssystem' icon='arrow left' onClick={unregisterERP} />
                        )}
                        <Button
                            color='green'
                            floated='right'
                            content='Bestil gennemgang'
                            icon='check circle'
                            labelPosition='right'
                            onClick={orderReview}
                            disabled={working || !hasBought}
                            loading={working}
                        />
                    </div>
                )}
            </div>
        );
    };

    if (loading || productInfoLoading) {
        return <Loader inline='centered' active />;
    }

    if (error) {
        return <Message><Icon name='warning sign' /> Der opstod en fejl</Message>;
    }

    return (
        <div>
            {renderBookingForm()}
        </div>
    );
};

export default ManualReviewBooker;