import React, { Component } from 'react';
import { Icon, Image, Segment, Loader, Message, Button, Popup, Modal, List, Header, Divider, Transition } from 'semantic-ui-react';
import i18n from 'i18n/pages/ErpData';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';
import { getOAUTHConsentLink } from 'http/dinero';
import { unregisterERP } from 'http/erpBroker';
import { refreshTokens } from 'network/fetch/refreshTokens';
import { compileMarkdown } from 'util/markdown';
import { formatDateTime } from 'util/format/DateTime';
import { getAccountPlansFact } from 'util/FactMapUtil';
import { hasQueryParam, unsetParam } from 'util/QueryParams';
import { isAccountant, isAdmin, isHolding } from 'util/userMethods';
import erpSystems from 'util/erpSystems';
import withUserData from 'util/withUserData';
import ERPConnector from 'design/molecules/ERPConnectorWidget';
import ColoredText from 'design/atoms/ColoredText';
import { withBreakpoints } from 'design/atoms/Media';
import ERPHeader from './ERPHeader';
import ManualAccounting from './ManualAccounting';
import ManualReviewBooker from './ManualAccounting/ManualReviewBooker';

const REFRESH_ERP_QUERY_PARAM = 'refresh_erp';

const ProgressStep = ({ label, tooltip, loading, error, warning, success, inactive, forgottenJournals }) => {
    let icon;
    let iconColor;

    if (loading) {
        icon = 'circle notched';
        iconColor = 'black';
    } else if (error) {
        icon = 'circle times';
        iconColor = 'red';
    } else if (warning) {
        icon = 'circle warning';
        iconColor = 'brown';
    } else if (success) {
        icon = 'circle check';
        iconColor = 'green';
    } else if (forgottenJournals) {
        icon = 'circle check';
        iconColor = 'yellow';
    } else {
        icon = 'circle outline';
        iconColor = 'grey';
    }

    return (
        <Popup
            disabled={!tooltip}
            trigger={
                <p style={{ opacity: inactive ? 0.5 : 1 }}>
                    {<Icon
                        name={icon}
                        color={iconColor}
                        loading={loading}
                    />}
                    {label}
                </p>
            }
            position='bottom left'
            content={<div children={tooltip} style={{ width: '500px' }} />}
        />
    );
};

class ERPdata extends Component {
    state = {
        loading: false,
        error: null,
        confirming: false,
    };

    componentDidMount = () => {
        if (hasQueryParam(REFRESH_ERP_QUERY_PARAM)) {
            unsetParam(REFRESH_ERP_QUERY_PARAM);
            this.runErpAction();
        }
    };

    erpChanged = async (...args) => {
        this.setState({ loading: true, error: null, erp: null });
        await refreshTokens();
        this.setState({ loading: false }, () => {
            this.runErpAction(...args);
        });
    };

    unregisterERP = async () => {
        this.setState({ loading: true });
        await this.runErpAction({ resetAccountPlan: true });
        await unregisterERP();
        this.setState({ loading: false });
    };

    getAccountplan = () => {
        const { values } = this.props;
        return getAccountPlansFact(values)?.value || {};
    };

    runErpAction = (context = {}) => {
        if (this.isHentTalDisabled()) {
            return ;
        }

        const { onChange } = this.props;
        const { trigger } = this.props.options;
        
        return onChange(trigger, {
            number: new Date().valueOf(),
        }, context);
    };

    isHentTalDisabled = () => {
        const { loading } = this.state;
        const { updating, isLocked } = this.props;

        return loading || isLocked || updating;
    };

    parseError = errorMessage => {
        if (!errorMessage) {
            return {};
        }

        const [errorCode, ...errorSpecificData] = errorMessage.split(' ');
        const lookup = i18n[errorCode];
        if (!lookup) {
            return { error: i18n.defaultErpErrorMsg };
        }

        const error = lookup + errorSpecificData.join(' ');
        return { error, errorCode };
    };

    changeBookkeepingSystemPressed = () => {
        if (!isAccountant(this.props.userData)) {
            return this.unregisterERP();
        }

        const accountsAreImported = this.getAccountplan()?.accounts?.length > 0;
        if (!accountsAreImported) {
            return this.unregisterERP();
        }

        this.setState({ confirming: true });
    };

    renderErrorMessage = (errorMessage, errorCode) => {
        if (this.props.updating) {
            return null;
        }

        if (!errorMessage) {
            return null;
        }

        const specialErrorMessages = {
            oauth_invalid_grant: () => {
                const handleOauthRedirect = async () => {
                    const redirectPath = `${window.location.pathname}?${REFRESH_ERP_QUERY_PARAM}=true`;
                    const oauthRedirect = await getOAUTHConsentLink(redirectPath, true);
                    window.location.href = oauthRedirect;
                };

                return (
                    <Message>
                        <div style={{ marginBottom: '0.5em' }}>
                            {errorMessage}
                        </div>
                        <ColoredText
                            link
                            bold
                            color='green'
                            icon='redo'
                            iconPosition='left'
                            content={i18n.reconnect}
                            onClick={handleOauthRedirect}
                        />
                    </Message>
                );
            },

            uniconta_invalid_session: () => {
                const handleUnicontaRelogin = async () => {
                    await unregisterERP();
                };
                return (
                    <Message>
                        <div style={{ marginBottom: '0.5em' }}>
                            {errorMessage}
                        </div>
                        <ColoredText
                            link
                            bold
                            color='green'
                            icon='redo'
                            iconPosition='left'
                            content={i18n.reconnect}
                            onClick={handleUnicontaRelogin}
                        />
                    </Message>
                );
            },
        };

        if (errorCode in specialErrorMessages) {
            return specialErrorMessages[errorCode]();
        }

        return (
            <Message
                icon='warning'
                negative
                content={compileMarkdown(errorMessage)}
            />
        );
    };

    renderProgressionStatus = (accountplan) => {
        const { error, errorCode } = this.parseError(accountplan.error);

        const erpStepLabels = [
            i18n.weAreFetchingAccounts,
            i18n.weAreConvertingAccountPlanToYearReport,
            i18n.weAreRevisingYourAccountPlanAndYearReport,
        ];

        const accountsAreImported = accountplan?.accounts?.length > 0;
        const accountplanRevisionNeeded = accountplan?.needsRevision && !accountplan?.revised;

        const renderedSteps = erpStepLabels.map((stepLabel, stepIdx) => {
            if (this.props.updating) {
                return <ProgressStep label={stepLabel} loading />;
            }

            if (error) {
                const errorStepIndex = 0; // errors can only be tied to the first step (for now)
                return (
                    <ProgressStep
                        label={stepLabel}
                        error={stepIdx === errorStepIndex}
                        inactive={stepIdx > errorStepIndex}
                    />
                );
            }

            if (!accountsAreImported) {
                return <ProgressStep label={stepLabel} />;
            }

            if (stepIdx === 0 && accountplan.numberofunbookedjournals > 0) {
                return (
                    <ProgressStep 
                        tooltip={this.renderForgottenJournalTooltip(accountplan.numberofunbookedjournals)}
                        label={stepLabel} 
                        forgottenJournals={true} 
                    />
                );
            }

            const isLastStep = stepIdx === erpStepLabels.length - 1;
            if (isLastStep && accountplanRevisionNeeded) {
                return (
                    <ProgressStep
                        tooltip={this.renderManualReviewTooltip()}
                        label={stepLabel}
                        warning
                    />
                );
            }

            return <ProgressStep label={stepLabel} success />;
        });
        
        return (
            <div>
                {this.renderErrorMessage(error, errorCode)}
                {renderedSteps}
            </div>
        );
    };

    renderManualReviewTooltip = () => {
        const tooltipMarkdown = `##${i18n.manualQualityControl}\n` +
        `${i18n.weReviewAndApproveTheFollowingInYourAnnualReport}\n` +
        `* ${i18n.thatChartOfAccountsAndFiguresHaveComeCorrectlyFromBookkeeping}\n` +
        `* ${i18n.thatTheChartOfAccountsCanBeConvertedIntoACorrectlyPreparedAnnualReport}\n` +
        `* ${i18n.properHandlingOfTaxDeductions}\n` +
        `* ${i18n.thatAnnualReportAgreesAndBalances}\n` +
        `${i18n.youJustContinueTheProcessAtDigitalRevisorAnswerQuestionsAndLookThroughTheAnnualReport}\n` +
        `${i18n.weWillSendAnEmailWhenTheAnnualReportHasBeenReviewedAndIsReadyForSignatureAndSubmission}\n` +
        `${i18n.weReviewItWithin24Hours}`;

        return compileMarkdown(tooltipMarkdown);
    };

    renderForgottenJournalTooltip = (numberOfUnbookedJournals) => {
        const tooltipMarkdown = `##Manglende posteringer\n` +
        `Da vi hentede tallene fra dit bogføringssystem, bemærkede vi, at der stadig var ${numberOfUnbookedJournals === 1 ? '1 postering' : `${numberOfUnbookedJournals} posteringer`}, som endnu ikke er bogførte.\n` +
        `Det er ikke nødvendigvis en fejl, men du skal være opmærksom på, at det kun er de bogførte posteringer, der overføres fra dit bogføringssystem til Digital Revisor.\n` +
        `Hvis posteringerne er foretaget i regnskabsåret, skal du bogføre dem og klikke på knappen ”opdater tal”, så du får alle posteringer med.\n` +
        `Hvis posteringerne er en fejl, skal du slette dem.\n`;

        return compileMarkdown(tooltipMarkdown);
    };

    renderLastUpdatedTimestamp = () => {
        const { values, extractValue } = this.props;
        const { trigger } = this.props.options;
        const timestamp = extractValue(values[trigger]);
        return timestamp && (
            <div style={{ textAlign: 'center' }}>
                <ColoredText color='grey'>
                    {i18n.lastFetch} {formatDateTime(timestamp)}
                </ColoredText>
            </div>
        );
    };

    renderCurrentERP = () => {
        const { erp } = this.props.userData;
        const erpSystem = erpSystems[erp];

        const logoContainer = erpSystem?.logo && (
            <div style={{ margin: '2em' }}>
                <Image
                    src={erpSystem.logo}
                    centered
                    size='medium'
                    alt={erp}
                />
            </div>
        );

        return (
            <div style={{ textAlign: 'center' }}>
                {logoContainer}
                <ColoredText
                    content={i18n.changeAccountingSystem}
                    color='blue'
                    icon='exchange'
                    onClick={this.changeBookkeepingSystemPressed}
                    link
                />
            </div>
        );
    };

    renderConfirmationModal = () => {
        if (!this.state.confirming) {
            return null;
        }

        const closeModal = () => this.setState({ confirming: false });

        return (
            <Modal open onClose={closeModal}>
                <Modal.Header content='Skift bogføringssystem' icon='exchange' />
                <Modal.Content>
                    <Icon name='info circle' fitted /> OBS:
                    <br />
                    Hvis du vælger at skifte bogføringssystem, vil de tilpasninger og data, du har foretaget i vores afslutningsark, blive slettet.
                    Dette inkluderer:
                    <br />
                    <List bulleted>
                        <List.Item>Efterposteringer der ikke er bogførte</List.Item>
                        <List.Item>Vedhæftede bilag på konti</List.Item>
                        <List.Item>Kommentarer til konti</List.Item>
                        <List.Item>Kontigrupperinger</List.Item>
                        <List.Item>Mapninger</List.Item>
                    </List>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Annullér'
                        color='black'
                        onClick={closeModal}
                    />
                    <Button
                        primary
                        basic
                        content='Skift bogføringssystem'
                        icon='exchange'
                        onClick={() => {
                            closeModal();
                            this.unregisterERP();
                        }}
                    />
                </Modal.Actions>
            </Modal>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Segment basic textAlign='center'>
                    <Loader inline active size='big' />
                </Segment>
            );
        }
        
        const accountplan = this.getAccountplan();
        const { userData } = this.props;

        if (USE_CUSTOM_DISCOUNT_SYSTEM() && !isAdmin(userData) && accountplan.manualReviewMode) {
            return <ManualReviewBooker {...this.props} runErpAction={this.runErpAction} />;
        }

        const { toInteractiveElement } = this.props;
        const { erp } = userData;

        // no erp => render connector
        if (!erp) {
            return (
                <ERPConnector
                    onERPChanged={this.erpChanged}
                    runErpAction={this.runErpAction}
                    toInteractiveElement={toInteractiveElement}
                />
            );
        }

        // manual bookkeeping selected
        if (erp === erpSystems.manual.id || erp === erpSystems.swedishSIE.id) {
            return (
                <ManualAccounting
                    {...this.props}
                    runErpAction={this.runErpAction}
                    fetchERPData={this.runErpAction}
                    unregisterERP={this.unregisterERP}
                />
            );
        }

        const currentYearAccounts = accountplan?.accounts || [];

        const allCurrentYearFiguresAreZero = currentYearAccounts.every(account => {
            return Number(account.amount) === 0;
        });

        const bookkeepingHelpButton = (
            !this.props.updating &&
            USE_CUSTOM_DISCOUNT_SYSTEM() &&
            isHolding(userData) &&
            !isAccountant(userData) &&
            currentYearAccounts.length > 0 &&
            allCurrentYearFiguresAreZero &&
            <Button
                basic
                fluid
                primary
                content='Få bogføringshjælp'
                style={{ marginTop: '0.5rem' }}
                onClick={() => this.runErpAction({ toggleManualReviewMode: true })}
            />
        );

        return (
            <div>
                <ERPHeader onChangeErpClicked={this.changeBookkeepingSystemPressed} />

                <Divider />

                <div>
                    <Header style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '1rem 0',
                        fontSize: '1rem',
                    }}>
                        {this.renderLastUpdatedTimestamp()}
                    </Header>
                </div>

                {this.renderProgressionStatus(accountplan)}

                <Button
                    fluid
                    primary
                    style={{ marginTop: '1rem' }}
                    disabled={this.isHentTalDisabled()}
                    onClick={() => this.runErpAction()}
                    content={(
                        accountplan?.accounts?.length > 0
                            ? i18n.updateAccountPlan
                            : i18n.fetchAccountPlan
                    )}
                />
                
                <Transition visible={!!bookkeepingHelpButton}>
                    <div>
                        <Divider />
                        Du ikke har bogført noget i dit holdingselskab dette for dette regnskabsår. Har du brug for hjælp?
                        {bookkeepingHelpButton}
                    </div>
                </Transition>
                
                {this.renderConfirmationModal()}
            </div>
        );
    }
}



export default withBreakpoints(withUserData(ERPdata));
