import React from 'react';
import { useParams } from 'react-router-dom';
import { getParam } from 'util/QueryParams';
import useDeferredRedirect from 'util/useDeferredRedirect';
import usePaymentState from '../shared/usePaymentState';
import PaymentShell from '../shared/PaymentShell';
import PaymentPanels from '../shared/PaymentPanels';
import PaymentOverview from '../shared/PaymentOverview';
import HighFive from '../shared/HighFive';
import { refreshTokens } from 'network/fetch/refreshTokens';
import * as payment from 'http/payment';
import useRestResource from 'util/useRestResource';

export const MANUEL_HOLDING_REVIEW_PRODUCT_ID = 'manualHoldingReview';

const getHoldingReviewProduct = () => {
    return payment.getAddonProductByID(MANUEL_HOLDING_REVIEW_PRODUCT_ID);
};

const PaymentFlowManualHoldingReview = () => {
    const { taxYear } = useParams();
    const { loading, error, data: holdingReviewProduct } = useRestResource({ fetcher: getHoldingReviewProduct });

    const getOrderLines = () => {
        if (loading || error) return [];

        return [
            {
                description: `${holdingReviewProduct.name} ${taxYear}`,
                price: holdingReviewProduct.price,
                metadata: {
                    addonProductPurchase: {
                        productID: MANUEL_HOLDING_REVIEW_PRODUCT_ID,
                        data: { taxYear },
                    },
                },
            }
        ];
    };

    const paymentState = usePaymentState({
        orderLines: getOrderLines(),
    });

    useDeferredRedirect(paymentState.paymentDone, getParam('redirect') || '/');

    const handlePayment = async () => {
        const { success } = await paymentState.performPaymentRequest();
        if (!success) {
            return;
        }

        await refreshTokens();
    };

    let children;
    if (paymentState.paymentDone) {
        children = <HighFive />;
    } else {
        children = (
            <PaymentPanels
                leftPanel={paymentState.renderPaymentMethod()}
                rightPanel={
                    <PaymentOverview
                        initialCoupon={paymentState.couponCode}
                        onCouponChange={paymentState.setCoupon}
                        orderLines={getOrderLines()}
                        extraContent={(
                            <div>
                                {paymentState.renderPayButton(handlePayment)}
                                {paymentState.renderError()}
                            </div>
                        )}
                    />
                }
            />
        );
    }

    return (
        <PaymentShell
            paymentDone={paymentState.paymentDone}
            title='Køb manuel gennemgang'
            disabled={paymentState.working}
            children={children}
        />
    );
};

export default PaymentFlowManualHoldingReview;