import { useBreakpoints } from "design/atoms/Media";
import { Button } from "semantic-ui-react";
import erpSystems from "util/erpSystems";
import useUser from "util/useUser";

const ERPHeader = ({ onChangeErpClicked, hideChangeErpButton }) => {
    const user = useUser();
    const breakpoints = useBreakpoints();

    const chosenErp = erpSystems[user.erp];

    if (!chosenErp) {
        return null;
    }

    return (
        <div
            style={{
                display:'flex',
                flexDirection: breakpoints.isMobile ? 'column' : 'row',
                gap: '0.5em',
            }}
        >
            <div style={{ flex: 1 }}>
                <img
                    src={chosenErp.logo}
                    style={{ height: '40px' }}
                    alt={chosenErp.name}
                />
            </div>
            {!hideChangeErpButton && (
                <div>
                    <Button
                        primary
                        basic
                        fluid
                        content='Skift bogføringssystem'
                        icon='exchange'
                        style={{ maxHeight: '60px' }}
                        onClick={onChangeErpClicked}
                    />
                </div>
            )}
        </div>
    );
};

export default ERPHeader;