const errorMessages = new Map([
    ['10', 'XBRL dokumentet kan ikke valideres'],
    ['23', 'XBRL dokumentet opfylder ikke Erhvervsstyrelsens krav'],
    ['24', 'XBRL dokumentet er valideret med Advis'],
    ['30', 'Årsrapportens PDF dokument kan ikke valideres'],
    ['31', 'Årsrapportens PDF overstiger den maksimale størrelse på 20 Mb'],
    ['40', 'Det signerede XML dokuments signatur kan ikke valideres'],
    ['41', 'Certifikatet der har signeret XML dokumentet er ikke validt'],
    ['43', 'Det signerede XML dokument kan ikke valideres'],
    ['44', 'Der kan kun anvendes medarbejder- eller virksomhedscertifikat ved indberetning til SKAT'],
    ['45', 'Det anvendte certifikat har ukendt udsteder'],
    ['46', 'Det anvendte certifikat er endnu ikke gyldigt'],
    ['47', 'Det anvendte certifikat er udløbet'],
    ['48', 'Det anvendte certifikat er tilbagekaldt'],
    ['50', 'Der er allerede registreret et regnskab for dette CVR nummer i denne periode – der kan ikke'],
    ['51', 'Der skal angives netop een destination'],
    ['55', 'XBRL dokumentet til Erhvervsstyrelsen skal være en Årsrapport eller Likvidationsregnskab'],
    ['56', 'XBRL dokumentet til Danmarks Statistik skal være en Regnskabsstatistik'],
    ['57', 'XBRL dokumentet til SKAT skal være en Selskabsselvangivelse'],
    ['58', 'Mangler registerXbrlEkstern i SOAP'],
    ['59', 'PDF dokumentet mangler'],
    ['60', 'PDF dokumentet overholder ikke størrelsesbegræsningen på 20 Mb'],
    ['72', 'CVR-nummer ikke fundet'],
    ['73', 'Danmarks Statistik svarer at der er fejl i den indberettede selskabsstatistik.'],
    ['74', 'Vi kan i øjeblikket ikke få forbindelse til Danmarks Statistiks systemer.'],
    ['80', 'REST-kaldets JSON data kunne valideres imod skemaet'],
    ['81', 'Kun ERST er understøttet som destination i REST-kaldet'],
    ['82', 'Inline XBRL (IXBRL) er ikke Base64 kodet i REST-kaldet'],
    ['98', 'Systemet er optaget - prøv venligst senere'],
    ['99', 'Der er opstået en fejl - kontakt venligst Erhvervsstyrelsen'],
]);

export default errorMessages;
