import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import i18n from 'i18n/pages/Product/index';
import Paywall from 'design/atoms/Paywall';
import ColoredText from 'design/atoms/ColoredText';
import { Media } from 'design/atoms/Media';
import ProductSegment from './components/ProductSegment';
import FieldGroup from './components/FieldGroup';
import TeaserWall from './components/TeaserWall';
import ClientDataTransferer from './components/ClientDataTransferer';
import { TOGGLE_ALL_FIELD_GROUPS } from './components/UiElementGroupToggler';
import { TypeCheckList, TypeDocGen, TypeRawErpViewer, TypeResource, TypeYearReportData } from './FieldTypes/Fact';
import Question from './FieldTypes/Question';
import Fact from './FieldTypes/Fact';
import UiElement, { UiElementTypes } from './FieldTypes/UiElement';
import styles from './Product.module.scss';
import { createResourceFactMap } from 'util/FactMapUtil';
import FieldRenderingContext from './FieldRenderingContext';
import { createResourceRenderingContext } from 'util/resource/ResourceRenderingContext';
import { HINT_SECTION } from 'flags';
import ProductElementInteraction from './field_interactivity/ProductElementInteraction';
import ProductElementInteractionData, { ProductElementInteractionDataTypes } from './field_interactivity/ProductElementInteractionData';

const TYPE_QUESTION      = 'question';
const TYPE_FACT          = 'fact';
const TYPE_UI            = 'ui';
const TYPE_RESOURCE      = 'resource';
const TYPE_GROUP         = 'group';
const TYPE_FREEBIE_GROUP = 'freebie-group';

const FREEBIE_HANDLERS = {
    NEXT_SECTION: {
        id: 'NEXT_SECTION',
        color: 'orange',
        icon: 'chevron right',
    },
    REDIRECT_TO_PAYMENT: {
        id: 'REDIRECT_TO_PAYMENT',
        color: 'green',
        icon: 'unlock',
    },
};

class ProductPage extends React.Component {
    getFieldComponent (type) {
		switch (type) {
			case TYPE_QUESTION:
				return Question;
            case TYPE_FACT:
                return Fact;
            case TYPE_UI:
                return UiElement;
            default:
				throw new Error(`Unknown field type '${type}'`);
		}
    }

    isQuestionValueProvided = value => {
        if (value === undefined || value === null) {
            return false;
        }
        
        if (typeof value === 'string' && !value) {
            return false;
        }

        return true;
    };

    /**
     * @param {*} fieldWrapper 
     * @param {FieldRenderingContext} renderingContext 
     */
    registerProgressInformation = (fieldWrapper, renderingContext) => {
        if (fieldWrapper.type !== TYPE_QUESTION) {
            return;
        }
        
        // don't count the progress of freebie fields
        // (if not bought yet)
        const currentFrame = this.getCurrentGroupFrame();
        if (currentFrame.currentlyRenderingFreebieFields && !this.props.hasPayed) {
            return;
        }

        currentFrame.totalProgressItems++;

        const { id, supply } = fieldWrapper.field;
        if (renderingContext.validationResult[id]) {
            currentFrame.anyInvalidFields = true;
        }
        
        const { extractValue } = this.props;
        const fieldAssociatedValue = extractValue(renderingContext.values[supply]);
        if (this.isQuestionValueProvided(fieldAssociatedValue)) {
            currentFrame.doneProgressItems++;
        }
    };

    pushNewGroupFrame = () => {
        this.groupFrames.push({
            currentlyRenderingFreebieFields: false,
            anyInvalidFields: false,
            totalProgressItems: 0,
            doneProgressItems: 0,
        });
    };

    // getCurrentGroupFrame = () => this.groupFrames.at(-1);
    getCurrentGroupFrame = () => this.groupFrames[this.groupFrames.length - 1];

    /**
     * @param {*} fieldWrapper 
     * @param {number} i 
     * @param {FieldRenderingContext} renderingContext 
     * @returns 
     */
    renderField = (fieldWrapper, i, renderingContext) => {
        const {
            extractValue,
            updating,
            pagePrefix,
            taxYear,
            isLocked,
            productStatus,
            hasPayed,
            factsBeingUpdated,
            isTeaser,
            primoModelData,
        } = this.props;

        if (!this.props.productData) {
            return null;
        }
        
        const { values, onChange, isVisible, validationResult } = renderingContext;
        const { productId } = this.props.productData;
        const { field, type, visibilityReferences, options: fieldOptions, ...fieldProps } = fieldWrapper;

        if (fieldProps?.styleOptions?.hideIfZero) {
            const factID = field?.id;
            const value = Number(values[factID]?.value?.number);
            if (value === 0) {
                return null;
            }
        }

        if (!isVisible(visibilityReferences, values)) {
            // invisible fields are not considered
            return null;
        }

        // --------------------------------------------------

        let fieldRender = null;

        switch(type) {
            case TYPE_GROUP: {
                // push a fresh group frame to the stack
                // to store information about the inner fields being rendered
                this.pushNewGroupFrame();

                // render inner fields of field group
                const innerGroupFields = this.postprocessFieldGroup(this.renderFields(field, renderingContext));

                // pop the group frame off the stack
                // it should now contain information about progress, questions, etc.
                const groupFrame = this.groupFrames.pop();

                // render the the group itself
                fieldRender = this.renderFieldGroup(
                    fieldWrapper,
                    innerGroupFields,
                    groupFrame,
                );
                break;
            }

            case TYPE_FREEBIE_GROUP: {
                const groupFrame = this.getCurrentGroupFrame();

                groupFrame.currentlyRenderingFreebieFields = true;
                const innerGroupFields = this.renderFields(field, renderingContext);
                groupFrame.currentlyRenderingFreebieFields = false;

                if(hasPayed) {
                    fieldRender = innerGroupFields; // paid => show field
                } else {
                    const { title, icon, btnText, handlerId, hideContents } = fieldWrapper.freebieGroup;
                    if(!hideContents) {
                        fieldRender = this.renderFreebieGroup(title, icon, btnText, handlerId, innerGroupFields); // render blurred field group
                    }
                }
                break;
            }

            case TYPE_RESOURCE: {
                // special resource fields
                // a variable amount of resources can be created by the user of the product
                fieldRender = this.renderResourceFields(fieldWrapper);
                break;
            }
            
            default: {
                this.registerProgressInformation(fieldWrapper, renderingContext);

                // prepare file upload ID
                const fileUploadID = renderingContext.createFileUploadID(fieldWrapper);

                const Component = this.getFieldComponent(type);
                const key = fieldWrapper.fieldId;
                fieldRender = (
                    <Component
                        key={key}
                        field={field}
                        onChange={onChange}
                        reloadProductData={this.props.reloadProductData}
                        runFieldAction={this.props.runFieldAction}
                        values={values}
                        extractValue={extractValue}
                        getValidationMessage={this.getValidationMessage}
                        location={this.props.location}
                        {...fieldProps}
                        updating={updating}
                        validationResult={validationResult}
                        pagePrefix={pagePrefix}
                        taxYear={taxYear}
                        productId={productId}
                        productMetadata={this.props.productMetadata}
                        isLocked={isLocked || fieldWrapper.isBlurred}
                        isTeaser={isTeaser}
                        productStatus={productStatus}
                        hasPayed={hasPayed}
                        files={this.props.files}
                        product={this.props.product}
                        productLabel={this.props.productLabel}
                        fieldOptions={fieldOptions}
                        factsBeingUpdated={factsBeingUpdated}
                        setActiveSection={this.props.setActiveSection}
                        unlockAndResetSignflow={this.props.unlockAndResetSignflow}
                        onFilesChange={this.props.onFilesChange}
                        paymentURL={this.props.paymentURL}
                        primoModelData={primoModelData}
                        currentPeriod={this.props.currentPeriod}
                        lastPeriod={this.props.lastPeriod}
                        fileUploadID={fileUploadID}

                        // Pass the function to wrap elements in an interactive wrapper
                        toInteractiveElement={this.toInteractiveElement}
                    />
                );
            }
        }

        // --------------------------------------------------

        const adjustedFieldRender = Array.isArray(fieldRender) ? fieldRender.map(fieldRender => fieldRender.renderedField) : fieldRender;
        const interactiveField = this.toInteractiveElement(
            new ProductElementInteractionData(ProductElementInteractionDataTypes.FIELD_WRAPPER, fieldWrapper.id, fieldWrapper),
            adjustedFieldRender
        );

        return HINT_SECTION() ? interactiveField : fieldRender;
    };

    // ==================================================

    /*
        Turns field render in an interactive field that can handle different interactions with the user. The events emitted are used utilized in HintSection.
    */
    toInteractiveElement = (interactionData, elementRender) => {
        const interactiveElement = (
            <div
                onClick={event => this.handleClick(interactionData, event)}
                onMouseEnter={event => this.handleMouseEnter(interactionData, event)}
                onMouseLeave={event => this.handleMouseLeave(interactionData, event)}
            >
                {elementRender}
            </div>
        );

        return interactiveElement;
    };

    // =========================

    // Handlers for all types of interactivity with product fields

    handleClick = (interactionData, event) => {
        const {
            onProductElementInteractionEnd,
            slug: sectionID
        } = this.props;

        const productElementInteraction = new ProductElementInteraction(sectionID, interactionData, event);
        onProductElementInteractionEnd(productElementInteraction);
    };

    handleMouseEnter = (interactionData, event) => {
        const {
            onProductElementInteractionStart,
            slug: sectionID
        } = this.props;

        const productElementInteraction = new ProductElementInteraction(sectionID, interactionData, event);
        onProductElementInteractionStart(productElementInteraction);
    };

    handleMouseLeave = (interactionData, event) => {
        const {
            onProductElementInteractionEnd,
            slug: sectionID
        } = this.props;
        
        const productElementInteraction = new ProductElementInteraction(sectionID, interactionData, event);
        onProductElementInteractionEnd(productElementInteraction);
    };

    // ==================================================

    /**
     * @param {*[]} fields 
     * @param {FieldRenderingContext} renderingContext 
     * @returns 
     */
    renderFields = (fields, renderingContext) => {
        const renderedFields = [];

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            const renderResult = this.renderField(
                field,
                i,
                renderingContext,
            );

            if (!renderResult) {
                continue;
            }

            if (Array.isArray(renderResult)) {
                renderedFields.push(...renderResult);
            } else {
                renderedFields.push({
                    field,
                    renderedField: renderResult,
                });
            }
        }
       
        return renderedFields;
    };

    checkFieldUiType = (field, type) => {
        return field?.field?.type === type;
    };

    isDivider = field => {
        return this.checkFieldUiType(field, UiElementTypes.divider.id);
    };

    isHeader = field => {
        return this.checkFieldUiType(field, UiElementTypes.header.id);
    };

    squashDividerDuplicates = fields => {
        const cleanedFields = [];

        for (let i = 0; i < fields.length; i++) {
            const current = fields[i];
            const next = fields[i + 1];

            if (this.isDivider(current?.field) && this.isDivider(next?.field)) {
                continue;
            }

            cleanedFields.push(current);
        }

        return cleanedFields;
    };

    removeOuterDividers = fields => {
        const fieldsCopy = [...fields];

        if (this.isDivider(fieldsCopy[0]?.field)) {
            fieldsCopy.splice(0, 1);
        }

        if (this.isDivider(fieldsCopy[fieldsCopy.length - 1]?.field)) {
            fieldsCopy.splice(-1, 1);
        }

        return fieldsCopy;
    };

    postprocessFieldGroup = groupFields => {
        groupFields = this.squashDividerDuplicates(groupFields);
        groupFields = this.removeOuterDividers(groupFields);

        return groupFields;
    };

    wrapUngroupedFields = renderedFields => {
        let currentGroup = [];
        let groups = [];

        const formatGroup = fields => {
            if (fields.length === 0) {
                return null;
            }

            const [firstField, ...restOfTheFields] = fields;
            if (this.isHeader(firstField?.field)) {
                if (restOfTheFields.length === 0) {
                    return firstField.renderedField;
                }

                return (
                    <FieldGroup
                        key={firstField.field.fieldId}
                        id={firstField.field.fieldId}
                        title={firstField.field.label}
                        children={restOfTheFields.map(current => current.renderedField)}
                        defaultOpen
                        collapsible
                        form
                    />
                );
            }

            return (
                <ProductSegment>
                    {fields.map(current => current.renderedField)}
                </ProductSegment>
            );
        };
        
        const flush = () => {
            if (currentGroup.length > 0) {
                const processedGroup = this.postprocessFieldGroup(currentGroup);
                const formattedGroup = formatGroup(processedGroup);
                formattedGroup && groups.push(formattedGroup);
            }
            currentGroup = [];
        };

        const shouldSkipWrappingField = field => {
            if (field?.type === TYPE_GROUP) {
                return true;
            }

            if (field?.type === TYPE_RESOURCE) {
                return true;
            }

            if (this.checkFieldUiType(field, 'group-toggler')) {
                return true;
            }

            if (this.checkFieldUiType(field, 'booking-discrepancy-checker')) {
                return true;
            }

            if (this.checkFieldUiType(field, UiElementTypes.segment.id)) {
                return true;
            }

            const fieldDataTypesNotToWrap = new Set([
                TypeYearReportData,
                TypeRawErpViewer,
                TypeCheckList,
                TypeResource,
                TypeDocGen,
            ]);

            if (fieldDataTypesNotToWrap.has(field?.field?.dataType)) {
                return true;
            }

            return false;
        };


        for (const current of renderedFields) {
            const { renderedField, field } = current;
            if (shouldSkipWrappingField(field)) {
                flush();
                groups.push(renderedField);
                continue;
            }

            currentGroup.push(current);
        }

        flush();

        return groups;
    };

    renderOuterFields = () => {
        const { fields } = this.props;
        const context = new FieldRenderingContext({
            values: this.props.productData?.values,
            onChange: this.props.fieldDataChanged,
            isVisible: this.props.isVisible,
            validationResult: this.props.validationResult,
            createFileUploadID: wrapper => wrapper.field.id,
        });

        // reset rendering state
        this.groupFrames = [];
        this.pushNewGroupFrame();

        const renderedFields = this.renderFields(fields, context);

        return this.wrapUngroupedFields(renderedFields);
    };

    renderResourceFields = ({ field }) => {
        const values = this.props.productData?.values;

        const allFields = [];

        // run through every resource that the user has created
        for (let { slug, fields } of field.resourceRenders) {
            // extract information about current resource
            const currentResource = get(values, `${field.resourceFact}.value.resourcesData.${slug}`, {});

            const resourceFactMap = createResourceFactMap(currentResource, slug, field, values);

            // prepare rendering context for current resource
            const renderingContext = createResourceRenderingContext(currentResource, field, values, slug, resourceFactMap, this.props.fieldDataChanged);

            // do render current resource fields...
            const resourceFields = this.renderFields(fields, renderingContext);

            // ...and append them to final output
            allFields.push(...resourceFields);
        }

        return allFields;
    };

    /**
     * @param {*} fieldWrapper 
     * @param {*} children 
     * @param {*} groupFrame 
     * @returns 
     */
    renderFieldGroup = (fieldWrapper, children, groupFrame) => {
        // Don't render if the group doesn't contain any visible fields
        if (children.length === 0) {
            return null;
        }

        // extract progress information from the group frame
        const { totalProgressItems, doneProgressItems, anyInvalidFields } = groupFrame;

        const { field, options: fieldOptions } = fieldWrapper;

        let groupCompleted;
        let isOpen;
        let progress;

        if (totalProgressItems > 0) {
            progress = Math.round(doneProgressItems / totalProgressItems * 100);
            groupCompleted = progress === 100;
            isOpen = this.shouldGroupBeOpen(field, groupCompleted);
        }

        // HACK: gets rid of the field group built by the model builder
        // remove when models are up to date
        const includesDocgen = fieldWrapper.field.some(({ field }) => {
            return field?.dataType === TypeDocGen;
        });

        if (includesDocgen) {
            return children;
        }

        const isDefaultOpen = (
            !fieldOptions?.alwaysClosed &&
            (fieldOptions?.openByDefault || isOpen)
        );

        return <FieldGroup
            key={fieldWrapper.fieldId}
            id={fieldWrapper.fieldId}
            title={fieldWrapper.label}
            topic={TOGGLE_ALL_FIELD_GROUPS}
            children={children.map(result => result.renderedField)}
            error={anyInvalidFields}
            progress={progress}
            defaultOpen={isDefaultOpen}
            completedQuestions={doneProgressItems}
            totalQuestions={totalProgressItems}
            collapsible
            form
        />;
    }

    renderFreebieGroup = (title, icon, btnText, handlerId, fields) => {
        if (fields.every(field => !field)) {
            return null;
        }
    
        const link = (
            <ColoredText
                link={this.props.paymentURL}
                content={i18n.freebieSectionTooltipHere}
                color='green'
            />
        );

        const [textBeforeLink, textAfterLink] = i18n.freebieSectionTooltip2.split('<link_container>');
        const tooltip = (
            <>
                {i18n.freebieSectionTooltip1}
                <br />
                <br />
                {textBeforeLink}{link}{textAfterLink}
            </>
        );

        const handlerMeta = FREEBIE_HANDLERS[handlerId];
    
        const freebieButton = btnText && handlerMeta && (
            <Button
                color={handlerMeta.color}
                icon={handlerMeta.icon}
                labelPosition='right'
                onClick={this.getFreebieGroupButtonHandler(handlerId)}
                content={btnText}
            />
        );
    
        const freebieTooltip = (
            <Popup
                hoverable
                position='top center'
                content={tooltip}
                trigger={
                    <span>
                        Låst i demo-version{' '}
                        <Icon name='question circle' color='grey' />
                    </span>
                }
            />
        );
    
        const freebieOverlay = (
            <div className={styles.freebieOverlay}>
                {freebieTooltip}
                <Media gte='tablet'>
                    {title && (
                        <b>
                            {icon && <Icon name={icon} />}
                            {title}
                        </b>
                    )}
                </Media>
                {freebieButton}
            </div>
        );
    
        return (
            <div className={styles.freebieGroup}>
                <div className={styles.relativeDiv}>
                    <div className={styles.fields}>
                        {fields.map(field => field.renderedField)}
                    </div>
                    {freebieOverlay}
                </div>
            </div>
        );
    };
    
    shouldGroupBeOpen = (fieldGroup, completed) => {
        if (fieldGroup.some(({ field }) => field?.dataType === TypeDocGen)) {
            return true;
        }
      
        if (!completed) {
            return true;
        }

        return false;
    };

    getFreebieGroupButtonHandler = (handlerId) => {
        let handler;
        switch(handlerId) {
            case FREEBIE_HANDLERS.NEXT_SECTION.id: {
                handler = this.props.next;
                break;
            }
            case FREEBIE_HANDLERS.REDIRECT_TO_PAYMENT.id: {
                const { history, paymentURL } = this.props;
                handler = () => history.push(paymentURL);
                break;
            }
            default: {
                handler = () => {};
            }
        }
        return handler;
    }

    getValidationMessage = (validationResult, id) => {
        return validationResult[id] || '';
    };

    renderCopyModule = () => {
        const {
            reloadProductData,
            dataIsCopyable,
            productLabel,
            isLocked,
            product,
        } = this.props;

        if (!dataIsCopyable) {
            return null;
        }

        return (
            <ClientDataTransferer
                reloadProductData={reloadProductData}
                productLabel={productLabel}
                productID={product}
                isLocked={isLocked}
            />
        );
    };

    render () {
        const { teaserWall, paywall } = this.props;

        if (teaserWall) {
            return <TeaserWall />;
        }

        if (paywall) {
            return <Paywall paymentURL={this.props.paymentURL}/>;
        }

        return (
            <>
                {this.renderCopyModule()}
                {this.renderOuterFields()}
            </>
        );
    }
}

export default withRouter(ProductPage);
