import React from 'react';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';
import { Icon, List } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';
import withUserData from 'util/withUserData';
import Tooltip from 'design/atoms/Tooltip';
import { isAccountant, isAdmin, isHolding } from 'util/userMethods';
import { postResource } from 'network/fetch';
import { erpIdentifiers } from '../';

const ConnectorLink = ({ content, onClick }) => {
    return (
        <ColoredText
            onClick={onClick}
            content={content}
            icon='arrow right'
            iconPosition='left'
            size='large'
            color='green'
            link
        />
    );
};

const ManualConnector = ({ onRegister, userData, runErpAction }) => {
    const doConnect = async (updateContext = {}) => {
        await postResource('erp', {
            service: erpIdentifiers.manual,
        });

        onRegister(updateContext);
    };

    const adminOrAccountant = isAdmin(userData) || isAccountant(userData);

    if (!adminOrAccountant) {
        if (USE_CUSTOM_DISCOUNT_SYSTEM()) {
            return (
                <div>
                    <strong>Bogføringsloven 2024 - Du skal have et bogføringssystem</strong>

                    <p>
                        Du skal have et online og godkendt bogføringssystem for at kunne bruge Digital Revisor.
                        Fra 2024 trådte en ny lovgivning i kraft, der bestemte, at alle selskaber skal være tilknyttet et digitalt bogføringssystem.
                        Det gælder også holdingselskaber. 
                    </p>

                    <p>
                        For at komme i gang skal du derfor vælge et af de ovenstående bogføringssystemer, som vi samarbejder med. 
                    </p>

                    <strong>Sådan kommer du i gang</strong>
                    <List ordered>
                        <List.Item>
                            Vælg et bogføringssystem ovenfor, der passer til dit holdingselskab&nbsp;
                            <Tooltip triggerOverwrite={<Icon name='question circle' color='grey' />}>
                                Ofte er der få posteringer i et holdingselskab,
                                og der findes bogføringssystemer, der er gratis til små selskaber
                            </Tooltip>
                        </List.Item>
                        <List.Item>
                            Opret holdingselskabet på bogføringsplatformen og bogfør dine tal
                        </List.Item>
                        <List.Item>
                            Når du er færdig, kobler du dit bogføringssystem op på Digital Revisor og laver årsrapporten
                        </List.Item>
                    </List>

                    <p>
                        Hvis du spørgsmål eller brug for hjælp,
                        er vi mere end klar til at svare på dine spørgsmål.
                        Skriv til os i chatten. 
                    </p>
                </div>
            );
        }

        // regular users
        return (
            <div>
                <p>
                    Bruger du ikke et bogføringssystem, kan vi stadig hjælpe dig med at skabe din årsrapport.
                    Du kan uploade relevante bilag hos os, og så gør vi tallene klar til årsrapporten for dig.
                </p>

                <ConnectorLink
                    onClick={() => doConnect()}
                    content='Få bogføringshjælp'
                />
            </div>
        );
    }

    if (isHolding(userData)) {
        return (
            <div>
                <p>
                    Du kan vælge at indtaste bogføringsdata manuelt i en kassekladde på Digital Revisor,
                    hvor vi udfylder tallene fra sidste år fra den indberettede XBRL. Du kan også vælge at importere bogføringsdata fra en CSV-fil.
                </p>
                <ConnectorLink
                    onClick={() => doConnect()}
                    content='Indtast bogføringsdata manuelt i kassekladde'
                />
                <br />
                <ConnectorLink
                    onClick={() => doConnect({ toggleCsvImporterMode: true })}
                    content='Importer bogføringsdata fra CSV'
                />
            </div>
        );
    }

    return (
        <div>
            <p>
                Bruger du ikke et af ovenstående økonomisystemer,
                kan du vælge at importere bogføringsdata fra en CSV-fil.
            </p>
            <ConnectorLink
                onClick={() => doConnect()}
                content='Importer bogføringsdata fra CSV'
            />
        </div>
    );
};

export default withUserData(ManualConnector);