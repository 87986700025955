import { postResource, deleteResource, putResource, getResource, patchResource } from '../network/fetch';
import { isAccountant } from '../util/userMethods';
import { makeQueryParams } from '../util/QueryParams';
import { USE_CUSTOM_DISCOUNT_SYSTEM } from 'flags';

const prefix = 'payments';

/**
 * Cancels a subscription to given product
 * @param {string} productID
 */
export async function cancelSubscription (productID) {
    return await deleteResource(`${prefix}/customers/subscriptions/product/${productID}`);
}

export function makePurchase ({
    products,
    creditsToBuy,
    addonProductPurchases,
    couponCode,
    cardToken,
    sessionToken,
    selectedPackageTier,
}) {
    return postResource(`${prefix}/customers/purchases`, {
        products,
        creditsToBuy,
        addonProductPurchases,
        couponCode,
        cardToken,
        sessionToken,
        selectedPackageTier,
    });
}

export function getDiscountedTaxYears() {
    if (!USE_CUSTOM_DISCOUNT_SYSTEM()) {
        return Promise.resolve([]);
    }

    return getResource(`${prefix}/customers/purchases/discounted-tax-years`);
}

/**
 * Updates the users card
 * @param {*} cardToken - Stripe card token
 * @param {*} user - Requesting user
 */
export async function updateCard (cardToken, user) {
    let updateCardPath;
    if (isAccountant(user)) {
        updateCardPath = 'accountant';
    } else {
        updateCardPath = 'customers';
    }
    return putResource(`${prefix}/${updateCardPath}/card`, { cardToken: cardToken.id });
}

/**
 * Gets the users card
 * @param {*} user - Requesting user
 */
export async function getCard (user) {
    let getCardPath;
    if (isAccountant(user)) {
        getCardPath = 'accountant';
    } else {
        getCardPath = 'customers';
    }
    return getResource(`${prefix}/${getCardPath}/card`);
}

/**
 * Gets the ID of the users default card
 * @returns {Promise<string>} ID of the card
 */
export function getAccountantCard () {
    return getResource(`${prefix}/accountant/card`);
}

export function getInvoiceMethod () {
    return getResource(`${prefix}/accountant/invoicemethod`); 
}

export function setInvoiceMethod (method) {
    return postResource(`${prefix}/accountant/invoicemethod`, { method }); 
}

export function getERPDeal (erp) {
    return getResource(`${prefix}/erp-deal/erp/${erp}`);
}

export function getPaymentLogs (query = {}) {
    const params = makeQueryParams(query);
    return getResource(`${prefix}/payment-log${params}`);
}

export function getPaymentRefundLogs(query = {}) {
    const params = makeQueryParams(query);
    return getResource(`${prefix}/payment-log/refunds${params}`);
}

export function countCustomerConversions (query = {}) {
    const params = makeQueryParams(query);
    return getResource(`${prefix}/payment-log/count${params}`);
}

export function verifyCoupon (couponId, productID) {
    return postResource(`${prefix}/verify-coupon/${productID}`, {
        couponId,
    });
}

export function confirmPayment (token) {
    return getResource(`${prefix}/customers/confirm/${token}`);
}

export function getSubscriptionsOverview () {
    return getResource(`${prefix}/customers/subscriptions/overview`);
}

export function getUnpaidSubscriptions () {
    return getResource(`${prefix}/customers/subscriptions/unpaid`);
}

export function payUnpaidSubscriptions (cardToken, sessionToken) {
    return postResource(`${prefix}/customers/subscriptions/unpaid/pay`, { cardToken, sessionToken });
}

export function getShoppingCartItems (productID, taxYear) {
    const taxYearSuffix = (
        taxYear
            ? `/taxYear/${taxYear}`
            : ''
    );
    return getResource(`${prefix}/products/cart-items/product/${productID}${taxYearSuffix}`);
}

export function unlockClientTaxYear(productID, taxYear) {
    return postResource(`${prefix}/accountant/unlock-tax-year`, {
        productID,
        taxYear,
    });
}

export function getAddonProductByID(productID) {
    return getResource(`${prefix}/addon-products/${productID}`);
}

export function hasAccessToAddonProduct(productID) {
    return getResource(`${prefix}/addon-products/${productID}/has-access`);
}

export function getAddonProductPurchases(productID) {
    return getResource(`${prefix}/addon-products/${productID}/purchases`);
}

export function getAddonProductPurchaseByID(productID, purchaseID) {
    return getResource(`${prefix}/addon-products/${productID}/purchases/${purchaseID}`);
}

export function patchAddonProductPurchaseData(productID, purchaseID, patchRequest) {
    return patchResource(`${prefix}/addon-products/${productID}/purchases/${purchaseID}/data`, patchRequest);
}

export function updateSubscriptionPackageTier(productID, newTier) {
    return patchResource(`${prefix}/customers/subscriptions/product/${productID}/tier`, {
        newTier,
    });
}