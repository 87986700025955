import React from 'react';
import { Transition } from 'semantic-ui-react';
import { formatDate } from 'util/format/DateTime';
import { useCreditsContext } from 'design/molecules/CreditsProvider';
import CreditsIcon from 'design/atoms/CreditsIcon';
import { ShoppingCartSegment } from '../shared/ShoppingCart';

const CreditsExpirationDateInfo = ({ expirationDate }) => {
    const { credits, loading } = useCreditsContext();

    return (
        <Transition visible={!loading}>
            <ShoppingCartSegment title='Udløbsdato' icon='clock'>
                <p>
                    De credits, du køber, vil kunne bruges indtil
                    {' '}<strong>d. {formatDate(expirationDate)}</strong>.
                    
                </p>
                
                {credits <= 0 && (
                    <p>
                        Ved køb af nye credits inden denne dato,
                        forlænges levetiden på allerede købte credits.
                    </p>
                )}

                {credits > 0 && (
                    <p>
                        Dine nuværende <strong><CreditsIcon orange /> × {credits}</strong> credits
                        {' '}vil også få deres nuværende udløbsdato rykket til ovenstående dato.
                    </p>
                )}
            </ShoppingCartSegment>
        </Transition>
    );
};

export default CreditsExpirationDateInfo;