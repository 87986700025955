import React from 'react';
import { Header } from 'semantic-ui-react';
import erpSystems from 'util/erpSystems';
import withUserData from 'util/withUserData';
import FoldableSegment from 'design/atoms/FoldableSegment';

import styles from './ERPList.module.scss';
import ProductSections from '../HintSection/util/ProductSections';

const ERPList = ({ userData, setActiveERP, selectedERP, onRegister, toInteractiveElement, runErpAction }) => {
    const renderSystems = () => {
        const systems = Object.values(erpSystems);

        return systems.map(({ id, name, isEligible, Connector, logo, connectTitle }) => {
            // ensure user is eligible to use the system
            if (isEligible instanceof Function) {
                if (!isEligible(userData)) {
                    return null;
                }
            }

            let title;

            if (logo) {
                // if logo, use that
                title = 
                    <img
                        src={logo}
                        className={styles.titleLogo}
                        alt={name}
                    />

            } else {
                // else use connect title
                title = <span className={styles.titleText}>{connectTitle}</span>;
            }

            return (
                <FoldableSegment
                    title={
                        <div style={{ display: 'flex', alignItems: 'center', minHeight: '40px' }}>{title}</div>
                    }
                    isOpen={selectedERP === id}
                    setIsOpen={() => setActiveERP(id)}
                    children={<Connector onRegister={onRegister} runErpAction={runErpAction} />}
                    hideChevron
                    collapsible
                    toInteractiveElement={toInteractiveElement}
                    interactionContext={
                        {
                            section: ProductSections.ERP_CONNECTION,
                            erpSystemID: id
                        }
                    }
                />
            );
        });
    };

    return (
        <div>
            <Header>Vælg dit bogføringssystem:</Header>
            {renderSystems()}
        </div>
    );
};

export default withUserData(ERPList);